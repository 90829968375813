:root {
    --body-bg-light: #fff;
    --body-bg-dark: #19222c;
    --body-text-light: #212529;
    --body-text-dark: #d3d5d8;
    --topnav-bg-light: #f6f6f8;
    --topnav-bg-dark: #222e3c;
    --side-bg-light: #fff;
    --side-bg-dark: #222e3c;
    --nav-bg-light: #fff;
    --nav-bg-dark: #19222c;
    --topnav-text-light: #878787;
    --topnav-text-dark: #a7abb1;
    --navbar-titles-light: #222222;
    --navbar-titles-dark: #d3d5d8;
    --container-title-light: #666666;
    --container-title-dark: #a7abb1;
    --footer-text-light: #858585;
    --footer-text-dark: #a7abb1;
    --random-text-light: #262626;
    --random-text-dark: #a7abb1;
    --copyright-text-light: #262626;
    --copyright-text-dark: #d3d5d8;
    --drop-bg-light: #fff;
    --drop-bg-dark: #222e3c;
    --drop-border-light: #ebebeb;
    --drop-border-dark: #253240;
    --order-title-light: #666666;
    --order-title-dark: #d3d5d8;
    --order-text-light: #414141;
    --order-text-dark: #a7abb1;
    --product-subtitle-light: #696969;
    --product-subtitle-dark: #a7abb1;
    --collapse-btn-light: buttontext;
    --collapse-btn-dark: #d3d5d8;

    --titles-light: #444444;
    --sub-titles-light: #222222;
    --main-text-light: #666666;
    --readmore-link-light: #999999;

    --titles-dark: #d3d5d8;
    --sub-titles-dark: #a7abb1;
    --main-text-dark: #fff;
    --readmore-link-dark: #999999;
    --remove-product-light: #414141;
    --remove-product-dark: #d3d5d8;
    --cancel-btn-light: #efefef;
    --cancel-btn-dark: #253240;
    --cancel-text-light: #444444;
    --cancel-text-dark: #d3d5d8;
    --side-close-light: #f1f1f1;
    --side-close-dark: #19222c;

    --footer-titles-light: #222222;
    --footer-titles-dark: #d3d5d8;

    --nav-shadow-light: 0px 3px 10px rgba(0, 0, 0, 0.2);
    --nav-shadow-dark: 0px 3px 10px rgba(128, 128, 128, 0.2);

    --specific-theme-red: #d32f2f;
    --specific-theme-blue: #01bad4;
    --specific-theme-green: #109533;
    --specific-theme-orange: #f57c00;

    --background-hover-red: rgba(211, 47, 47, 0.08);
    --background-hover-blue: rgba(1, 186, 212, 0.08);
    --background-hover-green: rgba(16, 149, 51, 0.08);
    --background-hover-orange: rgba(245, 124, 0, 0.08);
}

.custom-container {
    margin: auto 60px;
}

@media screen and (max-width: 768px) {
    .custom-container {
        margin: auto 16px;
    }
}

body {
    font-family: poppins;
}

body[data-theme="light"] {
    background-color: var(--body-bg-light);
    color: var(--body-text-light);
}

body[data-theme="dark"] {
    background-color: var(--body-bg-dark);
    color: var(--body-text-dark);
}

a {
    text-decoration: none !important;
    transition: 0.2s all linear;
}

body[data-color="red"] a {
    color: var(--specific-theme-red);
}

body[data-color="blue"] a {
    color: var(--specific-theme-blue);
}

body[data-color="green"] a {
    color: var(--specific-theme-green);
}

body[data-color="orange"] a {
    color: var(--specific-theme-orange);
}

p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--random-text-light);
    margin-bottom: 10px;
}

body[data-theme="dark"] p {
    color: var(--random-text-dark);
}

/* buttons */


/*buttons*/
.submit-btn {
    line-height: normal;
    font-size: 12px;
    line-height: 40px;
    height: 40px;
    text-transform: uppercase;
    background: #01bad4;
    color: #fff;
    padding: 0px 20px;
    border: 0px;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s all linear;
}

body[data-color="red"] .submit-btn {
    background: var(--specific-theme-red);
}

body[data-color="blue"] .submit-btn {
    background: var(--specific-theme-blue);
}

body[data-color="green"] .submit-btn {
    background: var(--specific-theme-green);
}

body[data-color="orange"] .submit-btn {
    background: var(--specific-theme-orange);
}

.submit-btn:hover {
    color: #ffffff;
    background-color: #01e0fe;
}

.submit-btn:focus {
    outline: none;
}

.submit-btn-invert {
    background-color: #01bad4;
}

body[data-color="red"] .submit-btn-invert {
    background-color: var(--specific-theme-red);
}

body[data-color="blue"] .submit-btn-invert {
    background-color: var(--specific-theme-blue);
}

body[data-color="green"] .submit-btn-invert {
    background-color: var(--specific-theme-green);
}

body[data-color="orange"] .submit-btn-invert {
    background-color: var(--specific-theme-orange);
}

.submit-btn-invert:hover {
    background-color: #222223;
}

.submit-btn[disabled] {
    background-color: #6f6f70 !important;
    cursor: default;
}

.submit-btn[disabled]:hover {
    background-color: #6f6f70;
    cursor: default;
}

body[dir="rtl"] .contact-form-wrapper .submit-btn {
    float: left !important;
}

.cancel-btn {
    line-height: normal;
    font-size: 12px;
    line-height: 40px;
    height: 40px;
    text-transform: uppercase;
    background: var(--cancel-btn-light);
    color: var(--cancel-text-lights);
    padding: 0px 20px;
    border: 0px;
    outline: none;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s all linear;
}

body[data-theme="dark"] .cancel-btn {
    background: var(--cancel-btn-dark);
    color: var(--cancel-text-dark);
}

.cancel-btn:hover {
    color: #444444;
    background-color: #d9d9d9;
}

.cancel-btn:focus {
    outline: none;
}

.checkout-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.checkout-section .items-incart-header {
    padding: 16px;
    border: 1px solid var(--drop-border-light);
}

body[data-theme="dark"].checkout-section .items-incart-header {
    border: 1px solid var(--drop-border-dark);
}

.checkout-section .items-incart-header h3 {
    margin-bottom: 0px;
    line-height: 24px;
}

.checkout-section .items-incart-header .fa-check {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    background-color: #2ea76f;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.login-form-header {
    font-family: poppins, sans-serif;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 18px;
    color: var(--navbar-titles-light);
}

body[data-theme="dark"] .login-form-header {
    color: var(--navbar-titles-dark);
}