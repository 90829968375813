/*Custom Dropdown*/

.custom-dropdown .dropdown-menu {
    min-width: 160px;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 0px;
    border: 0px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.12);
}

body[dir="rtl"] .custom-dropdown .dropdown-menu {
    transform: translate3d(-4px, 40px, 0px) !important;
}

body[data-theme="dark"] .custom-dropdown .dropdown-menu {
    background-color: var(--drop-bg-dark);
}

.custom-dropdown .dropdown-menu .dropdown-item {
    height: 40px;
    line-height: 40px;
    padding: 0px;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    transition: 0.2s all linear;
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #01bad4;
}

body[data-color="red"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: var(--specific-theme-red);
}

body[data-color="blue"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: var(--specific-theme-blue);
}

body[data-color="green"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: var(--specific-theme-green);
}

body[data-color="orange"] .custom-dropdown .dropdown-menu .dropdown-item:hover {
    background-color: var(--specific-theme-orange);
}

.custom-dropdown .dropdown-menu .dropdown-item a {
    display: flex;
    width: 40px;
    line-height: 40px;
    padding: 0px;
    color: #222222;
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    padding-left: 16px;
    text-decoration: none;
}

body[dir="rtl"] .custom-dropdown .dropdown-menu .dropdown-item a {
    padding-left: 0;
    padding-right: 16px;
}

body[data-theme="dark"] .custom-dropdown .dropdown-menu .dropdown-item a {
    color: #ffffff;
}

body[data-color="red"] .custom-dropdown .dropdown-menu .dropdown-item:hover a {
    color: #fff;
}

body[data-color="blue"] .custom-dropdown .dropdown-menu .dropdown-item:hover a {
    color: #fff;
}

body[data-color="green"] .custom-dropdown .dropdown-menu .dropdown-item:hover a {
    color: #fff;
}

body[data-color="orange"] .custom-dropdown .dropdown-menu .dropdown-item:hover a {
    color: #222222;
}