/* Custom input */
.custom-input {
  margin-bottom: 10px;
}

.custom-input input,
.custom-input textarea {
  border: 1px solid #dcdcdc;
  height: 40px;
  line-height: 40px;
  color: #999;
  padding: 0 15px;
  font-size: 13px;
  box-shadow: none;
  text-shadow: none;
  width: 100%;
}

body[data-theme="dark"] .custom-input input,
body[data-theme="dark"] .custom-input textarea {
  border: 1px solid var(--topnav-text-dark);
  color: #fff;
  background-color: var(--side-close-dark);
}

.custom-input input:focus,
.custom-input textarea:focus {
  outline: none;
  border: 1px solid #01bad4;
}

.custom-input label {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  color: var(--footer-text-light);
  display: block;
  text-transform: capitalize;
}

body[data-theme="dark"] .custom-input label {
  color: var(--footer-text-dark);
}

body[data-theme="dark"] .custom-input label {
  color: var(--body-text-dark) !important;
}

.custom-input.error-input input,
.custom-input.error-input textarea {
  border: 1px solid #d10024;
}

.custom-input.success-input input,
.custom-input.success-input textarea {
  border: 1px solid #2ea76f;
}

.custom-input .custom-input-error-message,
.custom-select-wrapper .custom-input-error-message {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  color: #d10024;
}


/* Custom checkbox*/

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: var(--copyright-text-light);
}

body[data-theme="dark"] .custom-checkbox {
  color: var(--copyright-text-dark);
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 10px;
  cursor: pointer;
}

/*===================*/

/* Custom Select*/
.custom-select-wrapper label {
  margin-right: 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #222222;
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 100px;
}

body[data-theme="dark"] .custom-select-wrapper label {
  color: var(--body-text-dark) !important;
}

body[dir="rtl"] .custom-select-wrapper label {
  float: right;
  text-align: right;
}

.custom-select-wrapper select {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  outline: none;
  border: 1px solid #ebebeb;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: #222222;
  padding: 0px 20px;
  margin-bottom: 15px;
}

body[data-theme="dark"] .custom-select-wrapper select {
  border: 1px solid var(--topnav-text-dark);
  color: #fff;
  background-color: var(--side-close-dark);
}

.custom-select-wrapper .select:focus {
  outline: none;
  box-shadow: none;
}

/*===================*/

/* Quanity selector */

.quantity-selector {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid var(--sub-titles-light);
  border-radius: 30px;
  padding: 0px 10px;
}

body[data-theme="dark"] .quantity-selector {
  border: 1px solid var(--sub-titles-dark);
}

.quantity-selector input {
  width: 50px;
  height: 40px;
  outline: none;
  border: 0px;
  border-radius: 0px;
  text-align: center;
  -webkit-appearance: none;
  background-color: transparent;
  color: var(--navbar-titles-light);
  appearance: none;
}

body[data-theme="dark"] .quantity-selector input {
  color: var(--body-text-dark) !important;
  background-color: transparent;
}

.quantity-selector input:focus {
  outline: none;
}

.quantity-selector input::-webkit-outer-spin-button,
.quantity-selector input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.quantity-selector input[type="number"] {
  -moz-appearance: textfield;
}

.quantity-selector label {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #262626;
  margin-right: 10px;
  min-width: 100px;
}

body[data-theme="dark"] .quantity-selector label {
  color: var(--main-text-dark);
}

body[dir="rtl"] .quantity-selector label {
  text-align: right;
}

.quantity-selector button {
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 0px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: var(--navbar-titles-light);
  background-color: transparent;
  border: 0px;
  transition: 0.3s all ease-in-out;
}

body[data-theme="dark"] .quantity-selector button {
  background-color: var(--side-close-dark) !important;
  color: var(--cancel-text-dark) !important;
}

.quantity-selector button.disabled {
  color: #999999;
  cursor: default;
}

.quantity-selector button.disabled:hover {
  color: #999999;
  cursor: default;
}

.quantity-selector button:focus {
  outline: none;
}

.quantity-selector button:hover {
  background-color: #ffffff;
  color: #01bad4;
  cursor: pointer;
}

body[data-color="red"] .quantity-selector button:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .quantity-selector button:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .quantity-selector button:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .quantity-selector button:hover {
  color: var(--specific-theme-orange);
}


/*===========*/

/*Custom notifications*/
.notification-success-wrapper,
.notification-error-wrapper {
  width: 320px;
  height: 120px;
  padding: 10px;
  position: relative;
}

.notification-success-wrapper {
  border: 1px solid #2ea76f;
}

.notification-error-wrapper {
  border: 1px solid #01bad4;
}

body[data-color="red"] .notification-error-wrapper {
  border: 1px solid var(--specific-theme-red);
}

body[data-color="blue"] .notification-error-wrapper {
  border: 1px solid var(--specific-theme-blue);
}

body[data-color="orange"] .notification-error-wrapper {
  border: 1px solid var(--specific-theme-orange);
}

.notification-success-wrapper .notif-dismiss,
.notification-error-wrapper .notif-dismiss {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f1f1f1;
  color: #666666;
  width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0px;
  border-radius: 0px;
  border: 0px;
  cursor: pointer;
  transition: 0.2s all linear;
}

.notification-success-wrapper .notif-dismiss:focus,
.notification-error-wrapper .notif-dismiss:focus {
  outline: none;
}

.notification-success-wrapper .notif-dismiss:hover,
.notification-error-wrapper .notif-dismiss:hover {
  background-color: #01bad4;
  color: #ffffff;
}

body[data-color="red"] .notification-success-wrapper .notif-dismiss:hover,
body[data-color="red"] .notification-error-wrapper .notif-dismiss:hover {
  background-color: var(--specific-theme-red);
  color: #ffffff;
}

body[data-color="blue"] .notification-success-wrapper .notif-dismiss:hover,
body[data-color="blue"] .notification-error-wrapper .notif-dismiss:hover {
  background-color: var(--specific-theme-blue);
  color: #ffffff;
}

body[data-color="green"] .notification-success-wrapper .notif-dismiss:hover,
body[data-color="green"] .notification-error-wrapper .notif-dismiss:hover {
  background-color: var(--specific-theme-green);
  color: #ffffff;
}

body[data-color="orange"] .notification-success-wrapper .notif-dismiss:hover,
body[data-color="orange"] .notification-error-wrapper .notif-dismiss:hover {
  background-color: var(--specific-theme-orange);
  color: #ffffff;
}

.notification-success-wrapper .notif-dismiss i,
.notification-error-wrapper .notif-dismiss i {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.notification-success-wrapper .notification-img-wrapper,
.notification-error-wrapper .notification-img-wrapper {
  width: 80px;
  height: 90px;
  overflow: hidden;
  margin-right: 10px;
}

.notification-success-wrapper .notification-img-wrapper img,
.notification-error-wrapper .notification-img-wrapper img {
  width: 100%;
  height: auto;
}

.notification-success-wrapper .notification-title-success,
.notification-error-wrapper .notification-title-error {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  color: #2ea76f;
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-transform: capitalize;
}

.notification-success-wrapper .notification-title-success {
  color: #2ea76f;
}

.notification-error-wrapper .notification-title-error {
  color: #01bad4;
}

body[data-color="red"] .notification-error-wrapper .notification-title-error {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .notification-error-wrapper .notification-title-error {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .notification-error-wrapper .notification-title-error {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .notification-error-wrapper .notification-title-error {
  color: var(--specific-theme-orange);
}

.notification-success-wrapper ul>li,
.notification-error-wrapper ul>li {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--container-title-light);
}

body[data-theme="dark"] .notification-success-wrapper ul>li,
.notification-error-wrapper ul>li {
  color: var(--container-title-dark);
}

/*shopping cart*/

body[dir="rtl"] .owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  direction: ltr;
}

body[dir="rtl"] nav>div.nav {
  margin-left: 0 !important;
  margin-right: auto !important;
}

body[dir="rtl"] img.mr-2 {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

body[dir="rtl"] i.fa.fa-caret-down.ml-1 {
  margin-right: 0.5rem !important;
  margin-left: 0 !important;
}

body[dir="rtl"] span.mr-3.d-none.d-md-inline-block {
  margin-left: 1rem;
  margin-right: 0 !important;
}

body[dir="rtl"] nav.nav.ml-auto {
  margin-left: 0 !important;
  margin-right: auto;
}

.main-menu-dropdown .dropdown-menu {
  padding: 0px;
  border-radius: 0px;
  border-top: 3px solid #01bad4;
  min-width: 140px;
  margin-top: 8px;
  left: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
}

body[data-color="red"] .main-menu-dropdown .dropdown-menu {
  border-top: 3px solid var(--specific-theme-red);
}

body[data-color="blue"] .main-menu-dropdown .dropdown-menu {
  border-top: 3px solid var(--specific-theme-blue);
}

body[data-color="green"] .main-menu-dropdown .dropdown-menu {
  border-top: 3px solid var(--specific-theme-green);
}

body[data-color="orange"] .main-menu-dropdown .dropdown-menu {
  border-top: 3px solid var(--specific-theme-orange);
}

.main-menu-dropdown .dropdown-menu::after {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: -20px;
  z-index: 10;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #01bad4;
}

body[data-color="red"] .main-menu-dropdown .dropdown-menu::after {
  border-bottom: 10px solid var(--specific-theme-red);
}

body[data-color="blue"] .main-menu-dropdown .dropdown-menu::after {
  border-bottom: 10px solid var(--specific-theme-blue);
}

body[data-color="green"] .main-menu-dropdown .dropdown-menu::after {
  border-bottom: 10px solid var(--specific-theme-green);
}

body[data-color="orange"] .main-menu-dropdown .dropdown-menu::after {
  border-bottom: 10px solid var(--specific-theme-orange);
}

.main-menu-dropdown .dropdown-menu .dropdown-item {
  padding: 0px;
}

.main-menu-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}

.main-menu-dropdown .dropdown-menu .dropdown-item a {
  display: block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0px 15px;
}

.categories-dropdown .dropdown-submenu {
  left: 100%;
  top: 0;
}

.body-cover-wrapper {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 1199;
  top: 0;
  left: 0;
  transition: 0.2s all linear;
}

.body-cover-wrapper-show {
  display: block;
}

body[dir="rtl"] ul.list-unstyled.list-group,
body[dir="rtl"] ul#side-menu-subcategory-list {
  text-align: start;
  padding-right: 0;
}

.featured-collections .featured-collections-item {
  width: 100%;
  height: 33%;
  overflow: hidden;
}

.featured-collections .featured-collections-item img {
  width: auto;
  height: 100%;
}

/*====================*/

/*TOP RATED SECTION*/

.top-rated-section {
  margin-top: 60px;
}

.top-rated-products-wrapper {
  padding: 15px;
  border: 1px solid #ebebeb;
  margin-top: 10px;
}

@-webkit-keyframes heartpulse {
  20% {
    transform: scale3d(1.2, 1.2, 1.2);
    z-index: 2;
  }

  30% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }

  40% {
    transform: scale3d(1.2, 1.2, 1.2);
    z-index: 2;
  }

  50% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }

  100% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }
}

@keyframes heartpulse {
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
    z-index: 2;
  }

  30% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1);
    z-index: 2;
  }

  50% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }

  100% {
    transform: scale3d(1, 1, 1);
    z-index: 0;
  }
}

body[dir="rtl"] .list-unstyled {
  padding-right: 0;
}

body[dir="rtl"] .list-unstyled li {
  text-align: right;
}

.filters-container h5,
.filters-title {
  text-align: start;
}

body[dir="rtl"] .filters-container h5::after {
  right: 0;
}

body[dir="rtl"] .custom-radio input[type="radio"],
body[dir="rtl"] .custom-checkbox input[type="checkbox"] {
  margin-right: 0 !important;
  margin-left: 10px;
}

/*====================*/

/*Featured products section*/
.featured-products-wrapper {
  padding: 15px;
  border: 1px solid #ebebeb;
  margin-top: 10px;
}

.featured-products-owl-carousel .owl-next,
.featured-products-owl-carousel .owl-prev,
.top-rated-products-owl-carousel .owl-next,
.top-rated-products-owl-carousel .owl-prev {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  line-height: 26px;
  text-align: center;
  color: #252525;
  background-color: #ebebeb !important;
  outline: none;
  transition: 0.2s all linear;
}

.featured-products-owl-carousel .owl-next:hover,
.featured-products-owl-carousel .owl-prev:hover,
.top-rated-products-owl-carousel .owl-next:hover,
.top-rated-products-owl-carousel .owl-prev:hover {
  background-color: #01bad4 !important;
  color: #ffffff !important;
}

body[data-color="red"] .featured-products-owl-carousel .owl-next:hover,
body[data-color="red"] .featured-products-owl-carousel .owl-prev:hover,
body[data-color="red"] .top-rated-products-owl-carousel .owl-next:hover,
body[data-color="red"] .top-rated-products-owl-carousel .owl-prev:hover {
  background-color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .featured-products-owl-carousel .owl-next:hover,
body[data-color="blue"] .featured-products-owl-carousel .owl-prev:hover,
body[data-color="blue"] .top-rated-products-owl-carousel .owl-next:hover,
body[data-color="blue"] .top-rated-products-owl-carousel .owl-prev:hover {
  background-color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .featured-products-owl-carousel .owl-next:hover,
body[data-color="green"] .featured-products-owl-carousel .owl-prev:hover,
body[data-color="green"] .top-rated-products-owl-carousel .owl-next:hover,
body[data-color="green"] .top-rated-products-owl-carousel .owl-prev:hover {
  background-color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .featured-products-owl-carousel .owl-next:hover,
body[data-color="orange"] .featured-products-owl-carousel .owl-prev:hover,
body[data-color="orange"] .top-rated-products-owl-carousel .owl-next:hover,
body[data-color="orange"] .top-rated-products-owl-carousel .owl-prev:hover {
  background-color: var(--specific-theme-orange) !important;
}

.featured-products-owl-carousel .owl-prev {
  position: absolute;
  right: 35px;
  top: -56px;
}

.featured-products-owl-carousel .owl-next {
  position: absolute;
  right: 0px;
  top: -56px;
}

.top-rated-products-owl-carousel .owl-prev {
  position: absolute;
  right: 20px;
  top: -56px;
}

.top-rated-products-owl-carousel .owl-next {
  position: absolute;
  right: -15px;
  top: -56px;
}

/*====================*/



body[data-theme="dark"] .owl-next,
body[data-theme="dark"] .owl-prev {
  color: #252525 !important;
}

/*====================*/

/*publicity banner section*/
.publicity-banner-section {
  margin-top: 40px;
}

.publicity-banner-section .publicity-wrapper {
  width: 100%;
  max-height: 360px;
  overflow: hidden;
  position: relative;
}

.publicity-banner-section .publicity-wrapper img {
  width: 100%;
  height: auto;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-cover {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-desc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-desc .publicity-banner-title {
  color: #efefef;
  text-transform: uppercase;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-desc h3 {
  color: #ffffff;
  text-transform: uppercase;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-desc a {
  color: #efefef;
  text-transform: uppercase;
}

.publicity-banner-section .publicity-wrapper .publicity-banner-desc a:hover {
  color: #01bad4;
}

body[data-color="red"] .publicity-banner-section .publicity-wrapper .publicity-banner-desc a:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .publicity-banner-section .publicity-wrapper .publicity-banner-desc a:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .publicity-banner-section .publicity-wrapper .publicity-banner-desc a:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .publicity-banner-section .publicity-wrapper .publicity-banner-desc a:hover {
  color: var(--specific-theme-orange);
}




/*========================*/

/* services section */

.services-section {
  margin-top: 40px;
  background-color: #444444;
  padding: 40px 0px;
}

.service-wrapper {
  padding: 10px 20px;
  border: 1px solid #575757;
}

.service-wrapper h6 {
  color: #ffffff;
  font-family: poppins, sans-serif;
  text-transform: uppercase;
}

.service-wrapper .service-icon {
  font-size: 40px;
  color: #ffffff;
  transition: 0.3s all ease-in-out;
}

.service-wrapper:hover .service-icon {
  color: #01bad4;
  transform: rotatey(360deg);
}

body[data-color="red"] .service-wrapper:hover .service-icon {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .service-wrapper:hover .service-icon {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .service-wrapper:hover .service-icon {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .service-wrapper:hover .service-icon {
  color: var(--specific-theme-orange);
}

.service-wrapper .service-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #efefef;
}


/* 2- SHOP PAGE */
/*==================================*/

.shop-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

/*Filters*/

.filters-wrapper .filters-header {
  background-color: #01bad4;
  height: 50px;
  width: 100%;
}

body[data-color="red"] .filters-wrapper .filters-header {
  background-color: var(--specific-theme-red);
}

body[data-color="blue"] .filters-wrapper .filters-header {
  background-color: var(--specific-theme-blue);
}

body[data-color="green"] .filters-wrapper .filters-header {
  background-color: var(--specific-theme-green);
}

body[data-color="orange"] .filters-wrapper .filters-header {
  background-color: var(--specific-theme-orange);
}

.filters-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: var(--topnav-text-light);
}

body[data-theme="dark"] .filters-title {
  color: var(--topnav-text-dark);
}

.filters-wrapper .filters-header h3 {
  font-family: poppins, sans-serif;
  font-size: 17px;
  line-height: 50px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 30px;
}

.filters-container {
  margin-top: 60px;
}

.filters-container h5 {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  font-family: poppins, sans-serif;
  text-transform: capitalize;
  color: var(--navbar-titles-light);
  padding-bottom: 10px;
  position: relative;
}

body[data-theme="dark"] .filters-container h5 {
  color: var(--navbar-titles-dark);
}

.filters-container h5::after {
  content: "";
  display: block;
  width: 70px;
  height: 2px;
  background-color: var(--navbar-titles-light);
  position: absolute;
  left: 0;
  bottom: 5px;
}

body[data-theme="dark"] .filters-container h5::after {
  background-color: var(--navbar-titles-dark);
}

.shop-categories li a {
  color: var(--sub-titles-light);
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-transform: capitalize;
}

body[data-theme="dark"] .shop-categories li a {
  color: var(--sub-titles-dark);
}

.shop-categories li a:hover {
  color: #01bad4;
}

body[data-color="red"] .shop-categories li a:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .shop-categories li a:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .shop-categories li a:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .shop-categories li a:hover {
  color: var(--specific-theme-orange);
}

.shop-categories li span {
  color: var(--sub-titles-light);
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  cursor: pointer;
  min-width: 20px;
  display: inline-block;
}

body[data-theme="dark"] .shop-categories li span {
  color: var(--sub-titles-dark);
}

.shop-categories li span:hover {
  color: #01bad4;
}

body[data-color="red"] .shop-categories li span:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .shop-categories li span:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .shop-categories li span:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .shop-categories li span:hover {
  color: var(--specific-theme-orange);
}

.shop-categories .subcategories {
  margin-left: 25px;
}

.shop-categories .subcategories li a {
  color: #666666;
}

.size-filter-wrapper .size-filters-list li {
  display: inline-block;
  min-width: calc(50% - 10px);
}

.color-filters-list li {
  display: inline-block;
  min-width: calc(50% - 10px);
}

.shop-featured-products {
  padding-top: 10px;
  border-top: 1px solid #ebebeb;
}

.shop-featured-products .owl-prev,
.shop-featured-products .owl-next {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #252525;
  background-color: #ebebeb !important;
  outline: none;
  transition: 0.2s all linear;
}

.shop-featured-products .owl-next:hover,
.shop-featured-products .owl-prev:hover {
  background-color: #01bad4 !important;
  color: #ffffff !important;
}

body[data-color="red"] .shop-featured-products .owl-next:hover,
body[data-color="red"] .shop-featured-products .owl-prev:hover {
  background-color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .shop-featured-products .owl-next:hover,
body[data-color="blue"] .shop-featured-products .owl-prev:hover {
  background-color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .shop-featured-products .owl-next:hover,
body[data-color="green"] .shop-featured-products .owl-prev:hover {
  background-color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .shop-featured-products .owl-next:hover,
body[data-color="orange"] .shop-featured-products .owl-prev:hover {
  background-color: var(--specific-theme-orange) !important;
}

.shop-featured-products .owl-prev {
  position: absolute;
  left: -10px;
  top: calc(50% - 10px);
}

.shop-featured-products .owl-next {
  position: absolute;
  right: -10px;
  top: calc(50% - 10px);
}

/*Side Mobile Filters*/

.side-filters-wrapper {
  position: fixed;
  left: -300px;
  top: 0;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 1198;
  width: 260px;
  height: 100vh;
  overflow: auto;
  background-color: #ffffff;
  transition: 0.2s all linear;
}

.side-filters-wrapper .filters-container {
  margin-top: 30px;
}

.side-filters-wrapper-show {
  left: 0px;
}

body[data-theme="dark"] .side-filters-wrapper-show {
  background-color: var(--body-bg-dark);
}

.side-filters-close {
  cursor: pointer;
}

.shop-mobile-cover {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  z-index: 1197;
  top: 0;
  left: 0;
  transition: 0.2s all linear;
}

.shop-mobile-cover-show {
  display: block;
}

.side-filters-toggler {
  border: 0px !important;
  font-size: 20px;
  margin-right: 10px;
  color: var(--topnav-text-light);
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #ebebeb;
}

body[data-theme="dark"] .side-filters-toggler {
  color: var(--topnav-text-dark);
}

body[dir="rtl"] .side-filters-toggler {
  margin-right: 0;
  margin-left: 10px;
}

body[dir="rtl"] .ml-auto.d-flex.align-items-center {
  margin-left: 0 !important;
  margin-right: auto;
}

.side-filters-toggler:focus {
  outline: none;
}

.sorting-filters-wrapper {
  min-height: 50px;
  width: 100%;
  background-color: var(--nav-bg-light);
  align-items: center;
  margin-bottom: 20px;
}

body[data-theme="dark"] .sorting-filters-wrapper {
  background-color: var(--nav-bg-dark);
}

.sorting-filters-wrapper .grid-btn,
.sorting-filters-wrapper .list-btn {
  background-color: transparent;
  color: #262626;
  border: 0px;
  outline: none;
  font-size: 24px;
  cursor: pointer;
}

.sorting-filters-wrapper .grid-btn:hover,
.sorting-filters-wrapper .list-btn:hover {
  color: #01bad4;
}

body[data-color="red"] .sorting-filters-wrapper .grid-btn:hover,
body[data-color="red"] .sorting-filters-wrapper .list-btn:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .sorting-filters-wrapper .grid-btn:hover,
body[data-color="blue"] .sorting-filters-wrapper .list-btn:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .sorting-filters-wrapper .grid-btn:hover,
body[data-color="green"] .sorting-filters-wrapper .list-btn:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .sorting-filters-wrapper .grid-btn:hover,
body[data-color="orange"] .sorting-filters-wrapper .list-btn:hover {
  color: var(--specific-theme-orange);
}

.sorting-filters-wrapper .grid-btn.active,
.sorting-filters-wrapper .list-btn.active {
  color: #01bad4;
}

body[data-color="red"] .sorting-filters-wrapper .grid-btn.active,
body[data-color="red"] .sorting-filters-wrapper .list-btn.active {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .sorting-filters-wrapper .grid-btn.active,
body[data-color="blue"] .sorting-filters-wrapper .list-btn.active {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .sorting-filters-wrapper .grid-btn.active,
body[data-color="green"] .sorting-filters-wrapper .list-btn.active {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .sorting-filters-wrapper .grid-btn.active,
body[data-color="orange"] .sorting-filters-wrapper .list-btn.active {
  color: var(--specific-theme-orange);
}

.sorting-filters-wrapper .grid-btn:focus,
.sorting-filters-wrapper .list-btn:focus {
  outline: none;
}

.sorting-filters-wrapper .items-found {
  font-size: 14px;
  color: var(--container-title-light);
}

body[data-theme="dark"] .sorting-filters-wrapper .items-found {
  color: var(--container-title-dark);
}

body[data-color="red"] .sorting-filters-wrapper .fa-times {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .sorting-filters-wrapper .fa-times {
  color: #007bff;
}

body[data-color="green"] .sorting-filters-wrapper .fa-times {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .sorting-filters-wrapper .fa-times {
  color: var(--specific-theme-orange);
}

.sorting-filter-dropdown a[data-toggle="dropdown"],
.sorting-filter-dropdown a#tags-filter {
  border: 1px solid var(--topnav-text-light);
  padding: 8px 20px;
  border-radius: 25px;
  color: var(--topnav-text-light);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
}

body[data-theme="dark"] .sorting-filter-dropdown a[data-toggle="dropdown"],
body[data-theme="dark"] .sorting-filter-dropdown a#tags-filter {
  border: 1px solid var(--topnav-text-dark);
  color: var(--topnav-text-dark);
}

.sorting-filter-dropdown .dropdown-menu {
  background-color: var(--nav-bg-light);
  box-shadow: 0 0px 20px 2px rgba(0, 0, 0, 0.1);
  min-width: 190px;
  border-radius: 3px;
  padding: 15px 0px;
  top: 10px !important;
}

body[data-theme="dark"] .sorting-filter-dropdown .dropdown-menu {
  background-color: var(--nav-bg-dark);
}

.sorting-filter-dropdown .dropdown-menu::before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-left: 10px solid transparent;
  border-top: 12px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid var(--drop-bg-light);
  position: absolute;
  top: -24px;
  right: 10px;
}

body[data-theme="dark"] .sorting-filter-dropdown .dropdown-menu::before {
  border-bottom: 12px solid var(--drop-bg-dark);
}

body[dir="rtl"] .sorting-filter-dropdown .dropdown-menu::before {
  right: unset;
  left: 10px;
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item {
  text-transform: capitalize;
  border-bottom: 1px solid var(--drop-border-light);
}

body[data-theme="dark"] .sorting-filter-dropdown .dropdown-menu .dropdown-item {
  border-bottom: 1px solid var(--drop-border-dark);
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px;
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item a {
  color: var(--topnav-text-light);
  font-size: 14px;
}

body[data-theme="dark"] .sorting-filter-dropdown .dropdown-menu .dropdown-item a {
  color: var(--topnav-text-dark);
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item.selected {
  background-color: rgba(86, 207, 225, 0.08);
}

body[data-color="red"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected {
  background-color: var(--background-hover-red);
}

body[data-color="blue"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected {
  background-color: var(--background-hover-blue);
}

body[data-color="green"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected {
  background-color: var(--background-hover-green);
}

body[data-color="orange"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected {
  background-color: var(--background-hover-orange);
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item.selected a {
  color: #56cfe1;
}

body[data-color="red"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected a {
  color: red;
}

body[data-color="blue"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected a {
  color: #56cfe1;
}

body[data-color="green"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected a {
  color: green;
}

body[data-color="orange"] .sorting-filter-dropdown .dropdown-menu .dropdown-item.selected a {
  color: #222222;
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: rgba(86, 207, 225, 0.08);
}

body[data-color="red"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--background-hover-red);
  color: red;
}

body[data-color="blue"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--background-hover-blue);
  color: #56cfe1;
}

body[data-color="green"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--background-hover-green);
  color: green;
}

body[data-color="orange"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--background-hover-orange);
  color: #222222;
}

.sorting-filter-dropdown .dropdown-menu .dropdown-item:hover a {
  color: #56cfe1;
}

body[data-color="red"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover a {
  color: red;
}

body[data-color="blue"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover a {
  color: #56cfe1;
}

body[data-color="green"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover a {
  color: green;
}

body[data-color="orange"] .sorting-filter-dropdown .dropdown-menu .dropdown-item:hover a {
  color: #222222;
}

/*Active filters*/
.active-filters-wrapper {
  padding: 10px 10px;
  background-color: #dededd;
  margin-bottom: 20px;
}

.active-filters-wrapper {
  padding: 15px 20px;
  background-color: #dededd;
}

.active-filters-wrapper .active-filters-header {
  color: #878787;
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
  margin-right: 15px;
  text-transform: capitalize;
}

.active-filters-wrapper li {
  padding: 10px;
  display: inline-block;
  background-color: #ffffff;
  color: #414141;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.active-filters-wrapper li a {
  margin-left: 10px;
}

/**/

/*Pagination*/

.pagination-wrapper {
  height: 50px;
  padding: 10px 10px;
}

body[data-theme="dark"] .pagination-wrapper {
  background-color: var(--topnav-bg-dark);
}

.shop-pagination .pagination-item {
  width: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0;
  margin-right: 5px;
}

.shop-pagination .pagination-previous,
.shop-pagination .pagination-next {
  width: auto;
  padding: 0px;
  text-transform: capitalize;
}

.shop-pagination .pagination-previous a,
.shop-pagination .pagination-next a {
  padding: 0px 12px;
}

.shop-pagination .pagination-item a {
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--nav-bg-light);
  color: var(--copyright-text-light);
  text-align: center;
  line-height: 30px;
}

body[data-theme="dark"] .shop-pagination .pagination-item a {
  background-color: var(--nav-bg-dark);
  color: var(--copyright-text-dark);
}

.shop-pagination .pagination-item.active a {
  color: #01bad4;
}

body[data-color="red"] .shop-pagination .pagination-item.active a {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .shop-pagination .pagination-item.active a {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .shop-pagination .pagination-item.active a {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .shop-pagination .pagination-item.active a {
  color: var(--specific-theme-orange);
}

.shop-pagination .pagination-item a:hover {
  color: #01bad4;
}

body[data-color="red"] .shop-pagination .pagination-item a:hover {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .shop-pagination .pagination-item a:hover {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .shop-pagination .pagination-item a:hover {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .shop-pagination .pagination-item a:hover {
  color: var(--specific-theme-orange);
}

.shop-pagination .pagination-item.disabled a {
  color: #999999;
}

.shop-pagination .pagination-item.disabled a:hover {
  background-color: #ffffff;
  color: #e2e7ea;
  cursor: default;
}

.shop-pagination .pagination-item.disabled a:hover {
  background-color: var(--cancel-btn-dark);
}

/*================*/

/* Add to cart Modal*/

#add-to-cart-modal .modal-dialog {
  max-width: 100% !important;
}

#add-to-cart-modal .modal-dialog .modal-content {
  width: 80%;
  margin: auto;
  background-color: #f5f7f8;
  border-radius: 4px;
  padding: 40px 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 40%);
}

body[data-theme="dark"] #add-to-cart-modal .modal-dialog .modal-content {
  background-color: var(--body-bg-dark);
}

body[data-theme="dark"] .modal-content {
  background-color: var(--body-bg-dark);
}

.modal-dismiss {
  position: absolute;
  top: 0px;
  right: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  outline: none;
  border: 0px;
  background-color: #ffffff;
  color: #262626;
  cursor: pointer;
}

.modal-dismiss:hover {
  background-color: #01bad4;
  color: #ffffff;
}

body[data-color="red"] .modal-dismiss:hover {
  background-color: var(--specific-theme-red);
}

body[data-color="blue"] .modal-dismiss:hover {
  background-color: var(--specific-theme-blue);
}

body[data-color="green"] .modal-dismiss:hover {
  background-color: var(--specific-theme-green);
}

body[data-color="orange"] .modal-dismiss:hover {
  background-color: var(--specific-theme-orange);
}

#add-to-cart-modal .add-to-cart-model .modal-content:before {
  display: block;
  content: "";
  width: 2px;
  position: absolute;
  top: 30px;
  bottom: 30px;
  left: 50%;
  margin-left: -1px;
  background: #d9d9d9;
  border-right: 1px solid #fff;
}

.add-to-cart-modal-product-details .product-img-wrapper {
  width: 180px;
  height: auto;
  overflow: hidden;
  margin-right: 15px;
}

.add-to-cart-modal-product-details .product-img-wrapper img {
  width: 100%;
  height: auto;
}

.add-to-cart-modal-product-details .modal-product-name {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-transform: capitalize;
}

body[dir="rtl"] .add-to-cart-modal-product-details .modal-product-name {
  text-align: right;
}

.add-to-cart-modal-product-details .modal-product-price {
  font-size: 18px;
  line-height: 20px;
  color: #01bad4;
  font-weight: 600;
}

body[data-color="red"] .add-to-cart-modal-product-details .modal-product-price {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .add-to-cart-modal-product-details .modal-product-price {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .add-to-cart-modal-product-details .modal-product-price {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .add-to-cart-modal-product-details .modal-product-price {
  color: var(--specific-theme-orange);
}

.add-to-cart-modal-product-details .modal-product-old-price {
  font-size: 14px;
  line-height: 20px;
  color: #666666;
  font-weight: 500;
  text-decoration: line-through;
}

.add-to-cart-total {
  height: 100%;
}

.add-to-cart-total h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}

body[dir="rtl"] .add-to-cart-total h5 {
  text-align: right;
}

.add-to-cart-total p {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 15px;
  color: #262626;
}

body[dir="rtl"] .add-to-cart-total p {
  text-align: right;
}

.add-to-cart-total p span {
  color: #01bad4;
  margin-left: 10px;
}

body[data-color="red"] .add-to-cart-total p span {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .add-to-cart-total p span {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .add-to-cart-total p span {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .add-to-cart-total p span {
  color: var(--specific-theme-orange);
}

.add-to-cart-total button {
  position: absolute;
  right: 15px;
  bottom: 0px;
}

@media screen and (max-width: 992px) {
  #add-to-cart-modal .add-to-cart-model .modal-content:before {
    display: none;
  }

  .add-to-cart-total button {
    position: unset;
    width: 100%;
    margin-top: 15px;
  }
}

/*===================*/
/*==================================*/
/*THE LOGIN PAGE */
/*================================= */

.login-section {
  margin-top: 40px;
  margin-bottom: 40px;
}



.login-section .login-form-wrapper {
  border: 1px solid var(--drop-border-light);
  padding: 20px;
}

body[data-theme="dark"] .login-section .login-form-wrapper {
  border: 1px solid var(--drop-border-dark);
}

.login-form-wrapper .custom-input label,
.contact-form-wrapper .custom-input label,
.form-wrapper .custom-input label {
  min-width: 120px;
}

.login-form-wrapper .custom-input input,
.contact-form-wrapper .custom-input input,
.form-wrapper .custom-input input {
  width: 100%;
}

body[dir="rtl"] .login-form-wrapper .custom-input label,
body[dir="rtl"] .contact-form-wrapper .custom-input label,
body[dir="rtl"] .form-wrapper .custom-input label {
  float: right;
  text-align: start;
}



.login-form-wrapper .auth-form-error-message,
.form-wrapper .auth-form-error-message {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: #01bad4;
}

body[data-color="red"] .login-form-wrapper .auth-form-error-message,
body[data-color="red"] .form-wrapper .auth-form-error-message {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .login-form-wrapper .auth-form-error-message,
body[data-color="blue"] .form-wrapper .auth-form-error-message {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .login-form-wrapper .auth-form-error-message,
body[data-color="green"] .form-wrapper .auth-form-error-message {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .login-form-wrapper .auth-form-error-message,
body[data-color="orange"] .form-wrapper .auth-form-error-message {
  color: var(--specific-theme-orange);
}

.facebook-login-wrapper h3 {
  font-family: poppins, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  color: var(--cancel-text-light);
  position: relative;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

body[data-theme="dark"] .facebook-login-wrapper h3 {
  color: var(--cancel-text-dark);
}

.facebook-login-wrapper h3::before {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(50% - 30px);
  height: 1px;
  background-color: var(--drop-border-light);
}

body[data-theme="dark"] .facebook-login-wrapper h3::before {
  background-color: var(--drop-border-dark);
}

.facebook-login-wrapper h3::after {
  content: "";
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: calc(50% - 30px);
  height: 1px;
  background-color: var(--drop-border-light);
}

body[data-theme="dark"] .facebook-login-wrapper h3::after {
  background-color: var(--drop-border-dark);
}

.facebook-login-btn {
  background-color: #3f5ca0 !important;
  color: #ffffff;
}

.facebook-login-btn:hover {
  background-color: #5b79be !important;
  color: #ffffff;
}

/*========================*/
/*THE PRODUCT DETAILS PAGE*/
/*========================*/

.product-details-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.product-details-section .checkout-form-wrapper {
  border: none;
  background-color: #f9f9f9;
}

body[data-theme="dark"] .product-details-section .checkout-form-wrapper {
  background-color: var(--body-bg-dark);
}

.product-details-section .custom-input input:where([type="text"], [type="tel"]) {
  background-color: transparent;
}


.product-details-section .custom-input input:where([type="text"], [type="tel"]):focus {
  background-color: white;
}

body[data-theme="dark"] .product-details-section .custom-input input:where([type="text"], [type="tel"]):focus {
  background-color: transparent;
}

.product-details-section .product-details-product-img-wrapper {
  width: 100%;
  height: auto;
  /* border-radius: 15px; */
  max-height: 600px;
  border: 1px solid #ebebeb;
  position: relative;
}

.product-details-section .product-details-product-img-wrapper img {
  width: 100%;
  height: auto;
  /* border-radius: 15px; */
}

.product-details-section .product-details-product-img-wrapper .add-wish-list {
  position: absolute;
  top: -10px;
  left: -10px;
  background-color: #01bad4;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 10px solid var(--body-bg-light);
  cursor: pointer;
  border: 0 !important;
}

body[dir="rtl"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  right: -10px;
}

body[data-theme="dark"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  outline: 10px solid var(--body-bg-dark);
}

body[data-color="red"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  background-color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  background-color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  background-color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
  background-color: var(--specific-theme-orange) !important;
}

.product-details-section .product-details-product-price {
  font-size: 28px;
  font-weight: 600;
  line-height: 46px;
  color: #01bad4;
  margin-inline-end: 0.5rem;
}

.product-details-section .fees-price {
  font-size: 17px;
  font-weight: 500;
  color: #01bad4;
  margin-inline-start: 0.25rem;
}

.my-btn .product-details-product-price {
  text-align: start;
}

body[data-color="red"] .product-details-section .product-details-product-price,
body[data-color="red"] .product-details-section .fees-price {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .product-details-section .product-details-product-price,
body[data-color="blue"] .product-details-section .fees-price {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .product-details-section .product-details-product-price,
body[data-color="green"] .product-details-section .fees-price {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .product-details-section .product-details-product-price,
body[data-color="orange"] .product-details-section .fees-price {
  color: var(--specific-theme-orange);
}

.product-details-section .product-details-product-old-price {
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  color: var(--product-subtitle-light);
  text-decoration: line-through;
}



.price-promotion {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  background-color: #01bad4;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 15px;
  margin-inline-start: 0.75rem;
  border-radius: 30px;
}

body[data-color="red"] .price-promotion {
  background-color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .price-promotion {
  background-color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .price-promotion {
  background-color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .price-promotion {
  background-color: var(--specific-theme-orange) !important;
}


/* Related products */

.related-products-section {
  margin-top: 40px;
}

.related-products-slider .owl-prev,
.related-products-slider .owl-next {
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  color: #252525;
  background-color: #ebebeb !important;
  outline: none;
  transition: 0.2s all linear;
}

.related-products-slider:hover .owl-prev,
.related-products-slider:hover .owl-next {
  opacity: 1;
}

.related-products-slider .owl-prev:hover,
.related-products-slider .owl-next:hover {
  background-color: #01bad4 !important;
  color: #ffffff !important;
}

body[data-color="red"] .related-products-slider .owl-prev:hover,
body[data-color="red"] .related-products-slider .owl-next:hover {
  background-color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .related-products-slider .owl-prev:hover,
body[data-color="blue"] .related-products-slider .owl-next:hover {
  background-color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .related-products-slider .owl-prev:hover,
body[data-color="green"] .related-products-slider .owl-next:hover {
  background-color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .related-products-slider .owl-prev:hover,
body[data-color="orange"] .related-products-slider .owl-next:hover {
  background-color: var(--specific-theme-orange) !important;
}

.related-products-slider .owl-prev {
  position: absolute;
  right: 50px;
  top: -30px;
}

.related-products-slider .owl-next {
  position: absolute;
  right: 15px;
  top: -30px;
}

/*==================*/






/*================*/
/*CONTACT US PAGE*/
/*=================*/

.contact-form-wrapper textarea {
  width: 100%;
  height: auto;
  line-height: 16px;
}


.sidenav-header h3 {
  font-family: poppins, sans-serif;
  font-size: 19px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  color: #222222;
}

.latest-posts-wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ebebeb;
}

.latest-posts-wrapper .latest-post-item {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 10px;
}

.latest-posts-wrapper .latest-post-item:last-child {
  border-bottom: 0px;
}

.latest-posts-wrapper .latest-post-item-img {
  width: 80%;
  max-height: 100px;
  overflow: hidden;
  margin-right: 10px;
}

.latest-posts-wrapper .latest-post-item-img img {
  width: 100%;
  height: auto;
}

.latest-post-item-details h5 {
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
}

.latest-post-item-details h5 a {
  color: #01bad4;
}

body[data-color="red"] .latest-post-item-details h5 a {
  color: var(--specific-theme-red);
}

body[data-color="blue"] .latest-post-item-details h5 a {
  color: var(--specific-theme-blue);
}

body[data-color="green"] .latest-post-item-details h5 a {
  color: var(--specific-theme-green);
}

body[data-color="orange"] .latest-post-item-details h5 a {
  color: var(--specific-theme-orange);
}

.latest-post-item-details .latest-post-item-date {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

/*comment section*/

.comments-section .comment-item {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 30px;
  margin-left: 20px;
  position: relative;
}

.comments-section .comment-item:last-child {
  border-bottom: 0px;
}

.comments-section .comment-user-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-inline-end: 10px;
  background-color: #01bad4;
  overflow: hidden;
}

body[data-color="red"] .comments-section .comment-user-img {
  background-color: var(--specific-theme-red);
}

body[data-color="blue"] .comments-section .comment-user-img {
  background-color: var(--specific-theme-blue);
}

body[data-color="green"] .comments-section .comment-user-img {
  background-color: var(--specific-theme-green);
}

body[data-color="orange"] .comments-section .comment-user-img {
  background-color: var(--specific-theme-orange);
}

.comments-section .comment-user-img img {
  width: 40px;
  height: auto;
}

.comments-section .comment-user-name {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.comments-section .comment-time {
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  color: var(--container-title-light);
  margin-inline-start: 0.5rem;
}

body[data-theme="dark"] .comments-section .comment-time {
  color: var(--container-title-dark);
}

.comments-section .comment-content p {
  font-style: italic;
}

/*===============================*/
/*==================*/
/* SHOPPING CART PAGE */
/*======================*/



.modal-body .fa-times {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: red;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.checkout-products-incart {
  padding: 16px;
}

.checkout-products-incart dt {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: var(--order-title-light);
  min-width: 120px;
}

body[data-theme="dark"] .checkout-products-incart dt {
  color: var(--order-title-dark);
}

.checkout-products-incart dd {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  color: var(--order-text-light);
}

body[data-theme="dark"] .checkout-products-incart dd {
  color: var(--order-text-dark);
}

body[dir="rtl"] .checkout-products-incart dt {
  text-align: right;
}

body[dir="rtl"] .checkout-products-incart dd {
  text-align: right;
}

.checkout-products-incart dd.status {
  color: #2ea76f;
}

.checkout-products-item {
  border-bottom: 1px solid var(--drop-border-light);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

body[data-theme="dark"] .checkout-products-item {
  border-bottom: 1px solid var(--drop-border-dark);
}

.checkout-products-item:last-child {
  border-bottom: 0px;
}

.checkout-products-item .checkout-products-item-img {
  max-width: 100px;
  max-height: 300px;
  overflow: hidden;
}

.checkout-products-item .checkout-products-item-img img {
  width: 100%;
  height: auto;
}

.checkout-products-item-name a {
  color: var(--navbar-titles-light);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

body[data-theme="dark"] .checkout-products-item-name a {
  color: var(--navbar-titles-dark) !important;
}

.checkout-products-item-name a:hover {
  color: #01bad4;
}

body[data-color="red"] .checkout-products-item-name a:hover {
  color: var(--specific-theme-red) !important;
}

body[data-color="blue"] .checkout-products-item-name a:hover {
  color: var(--specific-theme-blue) !important;
}

body[data-color="green"] .checkout-products-item-name a:hover {
  color: var(--specific-theme-green) !important;
}

body[data-color="orange"] .checkout-products-item-name a:hover {
  color: var(--specific-theme-orange) !important;
}

.checkout-products-item-price {
  font-size: 14px;
  color: var(--container-title-light);
  font-weight: 400;
  line-height: 17px;
}

body[data-theme="dark"] .checkout-products-item-price {
  color: var(--container-title-dark);
}

.checkout-products-item-properties {
  font-size: 14px;
  color: var(--container-title-light);
  font-weight: 400;
  line-height: 17px;
}

body[data-theme="dark"] .checkout-products-item-properties {
  color: var(--container-title-dark);
}

.checkout-products-remove-item {
  margin-right: 20px;
  float: right;
}

body[dir="rtl"] .col-md-3.col-6.col-sm-6.mb-sm-0 .float-right {
  float: left !important;
}

body[dir="rtl"] .checkout-products-remove-item {
  margin-left: 20px;
  float: left;
}

.checkout-products-remove-item a {
  font-size: 20px;
  color: var(--remove-product-light);
}

body[data-theme="dark"] .checkout-products-remove-item a {
  color: var(--remove-product-dark) !important;
}



.checkout-form-wrapper {
  padding: 20px;
  border: 1px solid var(--drop-border-light);
  margin-top: 20px;
}

body[data-theme="dark"] .checkout-form-wrapper {
  border: 1px solid var(--drop-border-dark);
}



.order-success-wrapper {
  padding: 16px;
  border: 1px solid var(--drop-border-light);
}

body[data-theme="dark"] .order-success-wrapper {
  border: 1px solid var(--drop-border-dark);
}

.order-success-wrapper .fa-check {
  font-size: 26px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #2ea76f;
  border-radius: 50%;
}

.order-success-wrapper .fa-times {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 24px;
  text-align: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: red;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
}

.order-success-wrapper h3 {
  font-size: 22px;
  line-height: 24px;
  color: var(--remove-product-light);
  font-weight: 400;
  text-transform: uppercase;
}

body[data-theme="dark"] .order-success-wrapper h3 {
  color: var(--remove-product-dark);
}

.orders-section {
  margin-top: 40px;
  margin-bottom: 40px;
}

.checkout-wrapper {
  border: 1px solid var(--drop-border-light);
  padding: 20px;
}

body[data-theme="dark"] .checkout-wrapper {
  border: 1px solid var(--drop-border-dark);
}

.checkout-wrapper dt,
.checkout-wrapper dd {
  font-size: 16px;
  line-height: 25px;
  font-weight: 500;
}

.checkout-wrapper .checkout-total dt,
.checkout-wrapper .checkout-total dd {
  font-size: 20px;
  line-height: 35px;
  font-weight: 500;
  color: var(--footer-titles-light);
}

body[data-theme="dark"] .checkout-wrapper .checkout-total dt,
.checkout-wrapper .checkout-total dd {
  color: var(--footer-titles-dark);
}

/*====================*/




.rating_num {
  font-size: 14px;
  margin-left: 5px;
  vertical-align: middle;
  display: inline-block;
}







/*================*/

.total-rating-wrapper {
  margin-top: 30px;
}

.total-rating-rate-value {
  font-size: 40px;
  line-height: 52px;
  color: #333333;
  font-weight: 700;
}

body[data-theme="dark"] .total-rating-rate-value {
  color: var(--body-text-dark) !important;
}



.total-reviews {
  margin-top: 4px;
  margin-bottom: 4px;
  color: #878787;
}

.rate-value-one-item {
  margin-bottom: 16px;
}

.rate-value-one-item .fa-star {
  color: #f6bc3e;
  margin-right: 6px;
}

.stars-number {
  color: #454545;
  margin-top: 4px;
  line-height: 20px;
  min-width: 15px;
}

body[data-theme="dark"] .stars-number {
  color: var(--body-text-dark) !important;
}

.rating-value-progress-bar {
  position: relative;
  width: 100%;
  min-width: 200px;
  background-color: #f1f1f1;
  display: block;
  height: 7px;
  margin-right: 20px;
  margin-left: 20px;
}

.rating-value-progress-bar-current-value {
  height: 7px;
  background-color: #f6bc3e;
  display: block;
}

.total-rates-item {
  border-radius: 2px;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  line-height: 20px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #454545;
}

body[data-theme="dark"] .total-rates-item {
  color: var(--body-text-dark) !important;
}

/*==============*/

.comment-product-rate-submit .rating_num {
  font-size: 22px;
}







/* EMPTY LISTS  */

.no-items-found {
  min-height: 320px;
  display: flex;
  align-items: center;
  width: 100%;
}

.no-items-found-msg {
  padding: 10px 20px;
  border: 2px solid #e0b252;
  border-radius: 4px;
  color: #af7d27;
  width: 100%;
}

/*==============*/

.owl-carousel {
  direction: ltr;
}

body[dir="rtl"] .form-check-input {
  position: unset;
  margin-left: 5px;
}

body[dir="rtl"] h6 {
  text-align: right;
}


body[dir="rtl"] .back-to-top-btn {
  right: unset;
  left: 20px;
}

body[data-theme="dark"] .cart-nb-items {
  color: var(--cancel-text-dark) !important;
}

.remove-coupon {
  margin-left: 5px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {

  .back-to-top-btn,
  .grecaptcha-badge {
    bottom: 75px !important;
  }

  .product-description-tabs .content {
    padding: 0px 1rem;
  }

  .product-details-section .product-details-product-img-wrapper .add-wish-list {
    width: 40px;
    height: 40px;
    outline: 8px solid var(--body-bg-light);
  }

  body[data-theme="dark"] .product-details-section .product-details-product-img-wrapper .add-wish-list {
    outline: 8px solid var(--body-bg-dark);
  }

  .my-btn .product-details-product-price {
    font-size: 20px;
    line-height: 30px;
  }

  .my-btn .product-details-product-old-price,
  .my-btn .fees-price {
    font-size: 14px;
    line-height: 20px;
  }

  .my-btn .price-promotion {
    font-size: 10px;
    padding: 3px 10px;
  }

  body[data-theme="dark"] .my-btn {
    background-color: var(--body-bg-dark);
  }

  .product-details-slider .item {
    max-height: 200px;
  }

  .product-details-slider .item img {
    height: 200px;
  }
}

@media screen and (max-width: 576px) {

  .back-to-top-btn {
    bottom: 110px !important;
  }
}





/* delivery selection */
.delivery-selector label {
  padding-inline-start: 0 !important;
}

.delivery-selector .delivery-input {
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.delivery-selector .delivery-info {
  align-items: center;
  background: #fff;
  border-radius: 11px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  position: relative;
  padding: 10px 0px;
  opacity: 0.5;
}

.delivery-selector .delivery-input+.delivery-info {
  border: 2px solid #dee2e6;
}

.delivery-selector .delivery-input:checked+.delivery-info {
  border: 2px solid var(--specific-theme-green);
  opacity: 1;
}

.delivery-selector .plan-header .plan-header-price {
  font-size: 18px;
  line-height: 28px;
  color: var(--specific-theme-green);
}

.delivery-selector .plan-header .plan-header-currency {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: var(--specific-theme-green);
  text-transform: capitalize;
  font-family: Montserrat, sans-serif;
}

.delivery-selector .delivery-agency .plan-header .plan-header-currency {
  text-align: center;
  line-height: normal;
  margin-top: 0.25rem;
}

.delivery-selector .delivery-agency .agency-img {
  max-width: 90%;
  height: auto;
  max-height: 35px;
}

#radio1 {
  accent-color: #01bad4;
  margin-left: 8px;
}

#label1 {
  margin-left: 32px;
}

.inline {
  display: inline;
}

svg {
  position: absolute;
  right: 32px;
}

#rec1 {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  color: #c7cacf;
  margin: 16px 0px 16px 0px;
  padding: 16px 0px 16px 0px;
}

.green {
  color: red;
}

body[data-color="red"] #radio1 {
  accent-color: var(--specific-theme-red);
}

body[data-color="blue"] #radio1 {
  accent-color: var(--specific-theme-blue);
}

body[data-color="green"] #radio1 {
  accent-color: var(--specific-theme-green);
}

body[data-color="orange"] #radio1 {
  accent-color: var(--specific-theme-orange);
}

body[data-theme="dark"] .form-control {
  background-color: transparent;
  color: #fff;
}

.info-banner {
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  min-height: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
  text-wrap: nowrap;
}

.info-banner a {
  text-decoration: underline !important;
}

.info-banner p {
  margin-bottom: unset;
  color: var(--body-text-dark) !important;
}

@keyframes rotateInfo {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

.moving-text {
  animation: rotateInfo 20s linear infinite;
}

.close-banner {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  background-color: transparent;
  color: #fff;
  border: none;
  z-index: 1001;
  cursor: pointer;
}

body[dir="rtl"] .categories-wrapper {
  text-align: right;
}
.categories-wrapper > div:not(:last-child) {
  border-bottom: 1px solid #ebe7e7 !important;
}

.keywords-body {
  background-color: #f7f7f7;
  padding: 10px 5px;
}
.keywords-body > a {
  text-decoration: none;
}
body[data-theme="dark"] .keywords-body {
  background-color: var(--topnav-bg-dark);
}

body[data-theme="dark"] hr {
  border: 0; border-top: 3px solid #253240;
}